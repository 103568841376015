import React from "react";
import salPhoto from "../assets/images/sal.png";
import solPhoto from "../assets/images/sol.png";
import theme from "../styles";
import { useMediaMax } from "../utils";
import UtilLinks from "./UtilLinks/UtilLinksComponent";

const ANPC = () => {
  const anpc = (
    <div className="d-flex flex-column">
      <a href="https://anpc.ro/ce-este-sal/">
        <img className="h-10" src={salPhoto}></img>
      </a>
      <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=RO">
        <img className="h-10" src={solPhoto}></img>
      </a>
    </div>
  );

  return anpc;
};

const CopyRight = () => {
  const copyRight = (
    <p
      className="text-lg mt-2"
      style={{
        color: theme.colors.customBlack,
        fontFamily: "Montserrat",
      }}
    >
      timelessmoments © {new Date().getFullYear().toString()}
    </p>
  );

  return copyRight;
};

export const Footer = () => {
  const isMobile = useMediaMax(theme.breakpoints.md);
  const flexDirection = isMobile
    ? "d-flex flex-column justify-center items-center w-full justify-around"
    : "d-flex flex-row w-full mt-2 justify-content-evenly";
  return (
    <footer
      className="d-flex flex-column justify-center items-center w-full mt-12 justify-around opacity-75"
      style={{
        backgroundColor: theme.colors.vanilaGrey,
      }}
    >
      <div className={flexDirection}>
        <UtilLinks></UtilLinks>
        <ANPC></ANPC>
      </div>

      <CopyRight></CopyRight>
    </footer>
  );
};
