export const homeURL = "/home";
export const photoboothURL = "/photobooth";
export const contactURL = "/contact";
export const politicaConfidentialitateURL = "/politica-de-confidentialitate";
export const politicaCookiesURL = "/politica-cookies";
export const solicitareOfertaURL = "/solicita-oferta-personalizata";
export const email1 = "contact@timelessmoments.ro";
export const email2 = "events.timelessmoments@gmail.com";
export const personalPhoneNumber = "0721 711 043";
export const personalLocation = "Oriunde in Romania";
export const instagramURL =
  "https://www.instagram.com/timelessmoments.ro/?hl=ro";
export const appTitle = "Timeless Moments";
