import { createGlobalStyle } from 'styled-components';
import colors from './styles/colors';

export default createGlobalStyle`   
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Lato", "Montserrat", "Cormorant Garamond", "Nunito", -apple-system, BlinkMacSystemFont, "Montserrat", "Cormorant Garamond", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: ${colors.white};
}  

canvas {
  display: block !important;
  vertical-align: bottom !important;
}

#tsparticles {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

`;
