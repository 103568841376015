const colors = {
  white: "#FFFFFF",
  whiteGrey: "#F6F6F6",
  customWhite: "#e2e8f0",
  customBlack: "#121212",
  lightBlack: "#222222",
  transparent: "transparent",
  cyanGreen: "#3ab497",
  cyanGreen70: "#48c89b",
  cyanGreen50: "#3aa8b4",
  cyanGreen30: "#48c5d2",
  cyan: "#03A9F4",
  darkCyan: "#29B6F6",
  lightCyan: "#b3e5fc",
  lightgreen: "#b3e5cc",
  vanilaIce: "#fcf8f4",
  vanilaGrey: "#f1eff0",
  textGrey: "#9d959c",
};

// I got the cyan colors from: https://github.com/vuetifyjs/vuetify/issues/4921

export default colors;
