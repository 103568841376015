import React from "react";
import { Gallery, Image } from "react-grid-gallery";
const image1 =
  "https://res.cloudinary.com/dd6vph7bm/image/upload/v1666721054/TimelessMoments/PhotoBoothHomePage/photos/photos/Castesardo_dpjr6p.jpg";
const image2 =
  "https://res.cloudinary.com/dd6vph7bm/image/upload/v1666721054/TimelessMoments/PhotoBoothHomePage/photos/photos/Delfi_xpwsfn.jpg";
const image3 =
  "https://res.cloudinary.com/dd6vph7bm/image/upload/v1666721053/TimelessMoments/PhotoBoothHomePage/photos/photos/Sunset_detcku.jpg";
const image4 =
  "https://res.cloudinary.com/dd6vph7bm/image/upload/v1666721054/TimelessMoments/PhotoBoothHomePage/photos/photos/Summer_ik3akr.jpg";
const image5 =
  "https://res.cloudinary.com/dd6vph7bm/image/upload/v1666721054/TimelessMoments/PhotoBoothHomePage/photos/photos/Navagio_dvppeh.jpg";

export const images: Image[] = [
  {
    src: image1,
    width: 4000,
    height: 2250,
    caption: "Castelsardo, Sardinia",
  },
  {
    src: image2,
    width: 4000,
    height: 2250,
    caption: "Delfi Beach, Zakynthos",
  },
  {
    src: image3,
    width: 4000,
    height: 3000,
    caption: "Sunset over Sardinia",
  },
  {
    src: image4,
    width: 3485,
    height: 3800,
    caption: "Summer",
  },
  {
    src: image5,
    width: 3000,
    height: 4000,
    caption: "Navagio, Zakynthos",
  },
];

const PhotoGallery = () => {
  return (
    <div className="px-8">
      <Gallery images={images} />
    </div>
  );
};

export default PhotoGallery;
