import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import theme from "../styles";
import { Col, Row } from "react-bootstrap";
import emailjs, { init } from "@emailjs/browser";
import { appTitle } from "../config";
import { useMediaMax } from "../utils";

const ContactForm = () => {
  window.document.title = `${appTitle}: Formular Contact`;

  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus("Sending...");
    const target = e.target as typeof e.target & {
      name: { value: string };
      phone: { value: string };
      email: { value: string };
      message: { value: string };
      date: { value: string };
      location: { value: string };
    };
    let details = {
      from_name: target.name.value,
      from_phone: target.phone.value,
      from_email: target.email.value,
      from_message: target.message.value,
      from_date: target.date.value,
      from_location: target.location.value,
    };

    emailjs
      .send(
        "solicitare_oferta",
        "solicitare_oferta",
        details,
        "aRFYB39U4VsR6lHh9"
      )
      .then(
        (_result) => {
          alert(
            "Am primit mesajul tău! Revenim cu un răspuns în cel mai scurt timp!"
          );
        },
        (_error) => {
          alert(
            "Ups! Ceva nu funcționează corespunzător! Incearcă să ne contactezi la adresa de email - contact@timelessmoments.ro sau la numărul de telefon 0721 711 043"
          );
        }
      );

    console.log(details);

    // 👇️ clear all input values in the form
    e.currentTarget.reset();

    setStatus("Submit");
  };
  const isMobile = useMediaMax(theme.breakpoints.md);
  return (
    <div className="d-flex justify-content-center">
      <div className="d-felx w-75">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label htmlFor="name">Numele dumneavoastră*:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Introduceți numele dumneavoastră"
              required
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label htmlFor="phone">Telefon*:</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Introduceți numarul dumneavoastră de telefon"
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label htmlFor="email">Email:</Form.Label>
                <Form.Control type="email" placeholder="name@example.com" />
              </Form.Group>
            </Col>
          </Row>

          {isMobile ? (
            <Row>
              <Form.Group className="mb-3" controlId="date">
                <Form.Label htmlFor="date">Data eveniment*:</Form.Label>
                <Form.Control type="date" required />
              </Form.Group>

              <Form.Group className="mb-3" controlId="location">
                <Form.Label htmlFor="location">
                  Localitate eveniment:
                </Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Row>
          ) : (
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="date">
                  <Form.Label htmlFor="date">Dată eveniment*:</Form.Label>
                  <Form.Control type="date" required />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="location">
                  <Form.Label htmlFor="location">
                    Localitate eveniment:
                  </Form.Label>
                  <Form.Control type="text" />
                </Form.Group>
              </Col>
            </Row>
          )}

          <Form.Group className="mb-3" controlId="message">
            <Form.Label htmlFor="message">Detalii:</Form.Label>
            <Form.Control as="textarea" rows={7} placeholder="Mesajul tau" />
            <Form.Text className="text-muted">
              Nu vom distribui datele dumneavoastră nimănui.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              Acord de prelucrare date cu caracter personal
            </Form.Label>
            <Form.Check
              required
              type="checkbox"
              id="default-checkbox"
              label="Da, vreau să primesc un răspuns"
            />
          </Form.Group>

          <Row className="justify-content-center">
            <button
              className="btn btn-secondary col-6"
              type="submit"
              style={{
                background: theme.colors.textGrey,
                color: theme.colors.white,
              }}
            >
              {status}
            </button>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
