import React from "react";
import theme from "../../styles";

function PoliticaCookies() {
  return (
    <div
      className="container w-75 justify-content-center"
      style={{
        color: theme.colors.customBlack,
        fontFamily: "Cormorant Garamond",
        fontSize: "18px",
      }}
    >
      <div className="row align-self-center">
        <p className="text-center fs-4">
          Despre politica noastră privind cookie-urile
        </p>
        <p className="text-center fs-6">
          Conform cu Regulamentul General Privind Protectia Datelor Personale
          (GDPR UE 679/2016)
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Ce sunt, cum si ce cookies folosim pe site-ul nostru.
        </p>
        <p>
          In aceasta politica privind cookie-urile, explicam ce sunt ele si
          modul in care le folosim, tipurile de cookie-uri pe care le utilizam,
          informatiile pe care le colectam folosind cookie-urile, modul in care
          sunt utilizate aceste informatii si cum sa controlam preferintele
          cookie-urilor. Consimtamantul dumneavoastra se aplica urmatoarelor
          domenii: timelessmoments.ro. Puteti retrage oricand consimtamantul
          aici: Schimbati Consimtamantul. Pentru informatii suplimentare despre
          cine suntem, cum ne puteti contacta, modul in care utilizam, stocam si
          pastram datele dumneavoastra personale in siguranta, consultati
          Politica de Confidentialitate.
        </p>
        <p>
          Te rugam sa citesti cu atentie informatiile care urmeaza: Acest
          website foloseste cookie-uri proprii si de la terti pentru a furniza
          vizitatorilor o experienta mai buna de navigare si servicii adaptate
          nevoilor si interesului fiecaruia.
        </p>
        <p>
          Aceste cookie-uri pot proveni de la urmatorii terti: Google Analytics,
          Facebook, Twitter, etc. Lista completa de cookie-uri de la terti poate
          fi consultata mai jos, in sectiunea Cookie-uri ale tertilor.
        </p>
        <p>
          De asemenea, pe acest website exista link-uri catre terte website-uri.
          Odata accesate aceste link-uri, utilizatorii se supun politicii
          website-ului respectiv.
        </p>
        <p>
          „Cookie”-urile joaca un rol important in facilitarea accesului si
          livrarii multiplelor servicii de care utilizatorul se bucura pe
          Internet, cum ar fi:
        </p>
        <ul>
          <li>
            - Personalizarea anumitor setari precum: limba in care este
            vizualizat un site, moneda in care se exprima anumite preturi sau
            tarife, pastrarea optiunilor pentru diverse produse (masuri, alte
            detalii etc).
          </li>
          <li>
            - Cookie-urile ofera detinatorilor de site-uri un feedback valoros
            asupra modului cum sunt utilizate site-urile lor de catre
            utilizatori, astfel incat sa le poata face mai eficiente si mai
            accesibile pentru utilizatori.
          </li>
          <li>
            - Permit aplicatiilor multimedia sau de alt tip de pe alte site-uri
            sa fie incluse intr-un anumit site pentru a crea o experienta de
            navigare mai valoroasa, mai utila si mai placuta.
          </li>
          <li>- Imbunatatesc eficienta publicitatii online.</li>
        </ul>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Ce este un „Cookie”?
        </p>
        <p>
          Un „Internet Cookie” (termen cunoscut si ca „browser cookie” sau „HTTP
          cookie” sau pur si simplu „cookie” ) este un fisier de mici
          dimensiuni, format din litere si numere, care este stocat pe
          computerul, terminalul mobil sau alte echipamente ale unui utilizator
          de pe care se acceseaza Internetul.
        </p>
        <p>
          Cookie-ul este instalat prin solicitara emisa de catre un web-server
          unui browser (ex: Internet Explorer, Chrome, Mozilla Firefox) si este
          complet „pasiv” (nu contine programe software, virusi sau spyware si
          nu poate accesa informatiile de pe hard drive-ul utilizatorului).
        </p>
        <p>
          Un cookie este format din 2 parti: numele si continutul sau valoarea
          cookie-ului. Mai mult, durata de existenta a unui cookie este
          determinata; tehnic, doar webserverul care a trimis cookie-ul il poate
          accesa din nou in momentul in care un utilizator se intoarce pe
          website-ul asociat webserverului respectiv.
        </p>
        <p>
          Cookie-urile in sine nu solicita informatii cu caracter personal
          pentru a putea fi utilizate si, in cele mai multe cazuri, nu
          identifica personal utilizatorii de internet.
        </p>
        <p>Exista 2 categorii mari de cookie-uri:</p>
        <ul>
          <li>
            - Cookie-uri de sesiune – acestea sunt stocate temporar in dosarul
            de cookie-uri al browserului web pentru ca acesta sa le memoreze
            pana cand utilizatorul iese de pe web-siteul respectiv sau inchide
            fereastra browserului ( ex: in momentul logarii/delogarii pe un cont
            de webmail sau pe retele de socializare).
          </li>
          <li>
            - Cookie-uri persistente – Acestea sunt stocate pe hard-drive-ul
            unui computer sau echipament (si in general depind de durata de
            viata prestabilita pentru cookie). Cookie-urile persistente le
            includ si pe cele plasate de un alt website decat cel pe care il
            viziteaza utilizatorul la momentul respectiv – cunoscute sub numele
            de ‘third party cookies’ (cookie-uri plasate de terti) – care pot fi
            folosite in mod anonim pentru a memora interesele unui utilizator,
            astfel incat sa fie livrata publicitate cat mai relevanta pentru
            utilizatori.
          </li>
        </ul>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Care sunt avantajele folosirii „Cookie”-urilor?
        </p>
        <p>
          Un „cookie” contine informatii care fac legatura dintre un web-browser
          (utilizatorul) si un web-server (website-ul). Daca un browser
          acceseaza acel web-server din nou, acesta poate citi informatia deja
          stocata si poate reactiona in consecinta. Cookie-urile asigura
          utilizatorilor o experienta placuta de navigare si sustin eforturile
          multor website-uri pentru a oferi servicii confortabile
          utilizatorillor: ex – preferintele in materie de confidentialitate
          online, optiunile privind limba site-ului, cosuri de cumparaturi sau
          publicitate relevanta.
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Care este durata de viata a unui „Cookie”?
        </p>
        <p>
          Cookieurile sunt administrate de webservere. Durata de viata a unui
          cookie poate varia semnificativ, depinzand de scopul pentru care este
          plasat. Unele cookie-uri sunt folosite exclusive pentru o singura
          sesiune (session cookies) si nu mai sunt retinute odata de
          utilizatorul a parasit website-ul si unele cookie-uri sunt retinute si
          refolosite de fiecare data cand utilizatorul revine pe acel website
          (‘cookie-uri permanente‘). Cu toate aceste, cookie-urile pot fi sterse
          de un utilizator in orice moment prin intermediul setarilor
          browserului.
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Ce sunt „Cookie”-urile administrate de terti?
        </p>
        <p>
          Anumite sectiuni de continut de pe unele site-uri pot fi furnizate
          prin intermediul unor terte parti sau furnizori (ex: un news box, un
          video sau o reclama). Aceste terte parti pot plasa de asemenea
          cookie-uri prin intermediul site-ului si ele se numesc „third party
          cookies” pentru ca nu sunt plasate de proprietarul website-ului
          respectiv. Furnizorii terti trebuie sa respecte de asemenea legea in
          vigoare si politicile de confidentialitate ale detinatorului
          site-ului.
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Cum sunt folosite „Cookie”-urile de catre siteul timelessmoments.com?
        </p>
        <p>
          Essentiale: Unele cookie-uri sunt esentiale pentru a putea experimenta
          functionalitatea completa a site-ului nostru. Acestea ne permit sa
          mentinem sesiunile utilizatorilor si sa prevenim orice amenintari la
          adresa securitatii. Nu colecteaza si nu stocheaza nicio informatie
          personala. De exemplu, aceste cookie-uri permit o incarcare mult mai
          rapida a paginilor.
        </p>
        <p>
          Statistici: Aceste cookie-uri stocheaza informatii precum numarul de
          vizitatori ai site-ului, numarul de vizitatori unici, ce pagini ale
          site-ului web au fost vizitate, sursa vizitei etc. Aceste date ne
          ajuta sa intelegem si sa analizam performanta site-ului web si unde
          are nevoie de imbunatatiri. Pentru statistici si analiza folosim codul
          Google Analytics.
        </p>
        <p>
          Functionale: Acestea sunt cookie-urile care ajuta anumite
          functionalitati neesentiale de pe site-ul nostru. Aceste
          functionalitati includ incorporarea de continut precum videoclipuri
          sau partajarea continutului site-ului web pe platformele de
          socializare.
        </p>
        <p>
          Preferinte: Aceste cookie-uri ne ajuta sa stocam setarile si
          preferintele de navigare, cum ar fi preferintele de limba sau paginile
          cele mai des accesate, astfel incat sa aveti o experienta mai buna si
          eficienta la viitoarele vizite pe site.
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Cum pot controla preferintele cookie-urilor?
        </p>
        <p>
          Daca decideti sa va eliminati consimțământul de utilizare a
          cookie-urilor ulterior prin sesiunea de navigare, puteti face clic pe:
        </p>
        <button
          type="button"
          className="btn btn-secondary btn-lg"
          onClick={() => {
            alert(
              "Continuând navigarea pe acest site web, sunteți de acord cu utilizarea cookie-urilor."
            );
          }}
        >
          Eliminați consimțământul
        </button>
        <p>Cum poti opri „Cookie”-urile?</p>
        <p>
          Dezactivarea si refuzul de a primi cookie-uri pot face anumite
          site-uri impracticabile sau dificil de vizitat si folosit. De
          asemenea, refuzul de a accepta cookie-uri nu inseamna ca nu vei mai
          primi/vedea publicitate online.
        </p>
        <p>
          Este posibila setarea din browser pentru ca aceste cookie-uri sa nu
          mai fie acceptate sau poti seta browserul sa accepte cookie-uri de la
          un site anume.
        </p>
        <p>
          Toate browserele moderne ofera posibilitatea de a schimba setarile
          cookie-urilor. Aceste setari se gasesc de regula in „optiuni” sau in
          meniul de „preferinte” al browserului tau.
        </p>
        <p>
          Pentru a intelege aceste setari, urmatoarele linkuri pot fi
          folositoare, altfel poti folosi optiunea „Ajutor”/”Help” a browserului
          pentru mai multe detalii.
        </p>
        <a href="http://windows.microsoft.com/ro-ro/internet-explorer/delete-manage-cookies#ie=ie-11">
          Setari cookie-uri Internet Explorer
        </a>
        <a href="https://support.mozilla.org/ro/kb/activarea-si-dezactivarea-cookie-urilor">
          Setari cookie-uri Mozilla Firefox
        </a>
        <a href="https://support.google.com/accounts/answer/61416?hl=ro">
          Setari cookie-uri Chrome
        </a>
        <a href="https://support.apple.com/kb/PH17191?viewlocale=ro_RO&amp;locale=en_US">
          Setari cookie-uri Safari
        </a>
        <p>
          Pentru setarile cookie-urilor generate de terti, poti consulta si
          site-ul:
        </p>
        <a href="http://www.youronlinechoices.com/ro/">
          http://www.youronlinechoices.com/ro/
        </a>
      </div>
    </div>
  );
}

export default PoliticaCookies;
