import React from "react";
import { appTitle } from "../config";
import image5 from "../assets/photos/Autumn.jpg";
import PhotoGallery from "../components/PhotoGallery";
import theme from "../styles";
import cld from "../components/CloudinaryInstance";
import { AdvancedImage } from "@cloudinary/react";

const image = cld.image(
  "v1666721264/TimelessMoments/PhotoBoothHomePage/photos/photos/Autumn_afwgb8"
);

const QuoteBanner = () => {
  const banner = (
    <div
      className="d-flex justify-content-center align-self-center"
      style={{
        color: theme.colors.customBlack,
        background: theme.colors.vanilaIce,
        borderBottom: `1px solid ${theme.colors.transparent}`,
      }}
    >
      <h1
        className="text-3xl my-4 text-center align-self-center font-normal"
        style={{
          color: theme.colors.textGrey,
          fontFamily: "Cormorant Garamond",
        }}
      >
        Natural and Timeless Photography to Capture Your Memories!
      </h1>
    </div>
  );

  return banner;
};

const Home = () => {
  window.document.title = `${appTitle}: Home`;
  return (
    <>
      <QuoteBanner></QuoteBanner>
      <div className="container max-w-screen-lg mx-auto">
        <div className="flex flex-col justify-center items-center px-8">
          <div>
            <AdvancedImage cldImg={image} />
          </div>
          <h3
            className="text-2xl mt-4 md:mt-2 w-full text-center font-light leading-relaxed"
            style={{ fontFamily: "Lato" }}
          >
            Hey there! This is TIMELESS MOMENTS. We'll come up with great
            things. Stay tuned!
          </h3>
        </div>
        <PhotoGallery></PhotoGallery>
      </div>
    </>
  );
};

export default Home;
