import React from "react";
import CookieConsent from "react-cookie-consent";
import theme from "../styles";

const CookieConsentComponent = () => {
  return (
    <CookieConsent
      location="bottom"
      visible="byCookieValue"
      enableDeclineButton={true}
      hideOnAccept={true}
      hideOnDecline={false}
      onDecline={() => {
        alert(
          "Continuand navigarea pe acest site web, sunteti de acord cu utilizarea cookie-urilor."
        );
      }}
      buttonText="Accept"
      declineButtonText="Refuz"
      cookieName="CookieConsent"
      style={{
        background: theme.colors.white,
        color: theme.colors.customBlack,
      }}
      buttonStyle={{
        background: "#61a229",
        color: theme.colors.customWhite,
        fontSize: "12px",
      }}
      declineButtonStyle={{
        background: "#3566bb",
        color: theme.colors.customWhite,
        fontSize: "12px",
      }}
      expires={150}
    >
      <span style={{ fontSize: "12px" }}>
        Timeless Moments Photography folosește cookie-uri pentru funcționarea
        corectă a site-ului și pentru a vă personaliza și îmbunătăți experiența
        de utilizare. Continuând navigarea pe acest site web, sunteți de acord
        cu utilizarea lor. Vă puteți retrage oricând consimțământul.
      </span>
    </CookieConsent>
  );
};
export default CookieConsentComponent;
