import React from "react";
import { NavLink } from "react-router-dom";
import { politicaConfidentialitateURL, politicaCookiesURL } from "../../config";
import theme from "../../styles";
import { useMediaMax } from "../../utils";

export const UtilLinks = () => {
  const isMobile = useMediaMax(theme.breakpoints.md);
  const expanded = isMobile ? "false" : "true";
  const className = isMobile ? "collapse hide" : "collapse show";
  const links = (
    <div>
      <p>
        <button
          className="btn btn-link dropdown-toggle"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded={expanded}
          aria-controls="collapseExample"
          style={{
            color: theme.colors.customBlack,
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "bold",
            textDecoration: "none",
          }}
        >
          LINK-URI UTILE
        </button>
      </p>

      <div className={className} id="collapseExample">
        <div className="d-flex flex-column">
          <a
            href="https://anpc.ro/"
            style={{
              color: theme.colors.customBlack,
              fontFamily: "Montserrat",
              fontSize: "14px",
              textDecoration: "none",
            }}
          >
            <p>ANPC</p>
          </a>
          <a
            href="https://www.dataprotection.ro/"
            style={{
              color: theme.colors.customBlack,
              fontFamily: "Montserrat",
              fontSize: "14px",
              textDecoration: "none",
            }}
          >
            <p>ANSPDCP</p>
          </a>

          <NavLink
            to={politicaConfidentialitateURL}
            title="POLITICA DE CONFIDENTIALITATE"
            aria-label="politica-de-confidentialitate"
            style={{
              color: theme.colors.customBlack,
              fontFamily: "Montserrat",
              fontSize: "14px",
              textDecoration: "none",
            }}
          >
            <p>POLITICA DE CONFIDENTIALITATE</p>
          </NavLink>
          <NavLink
            to={politicaCookiesURL}
            title="POLITICA COOKIES"
            aria-label="politica-cookies"
            style={{
              color: theme.colors.customBlack,
              fontFamily: "Montserrat",
              fontSize: "14px",
              textDecoration: "none",
            }}
          >
            <p>POLITICA COOKIES</p>
          </NavLink>
        </div>
      </div>
    </div>
  );

  return links;
};

export default UtilLinks;
