import React from "react";
import { appTitle, solicitareOfertaURL } from "../config";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { useMediaMax } from "../utils";
import theme from "../styles";
import { NavLink } from "react-router-dom";
import "./CabinaFotoHome.css";
import cld from "../components/CloudinaryInstance";
import { AdvancedVideo } from "@cloudinary/react";

const bannerDesktop = cld.video(
  "v1666810523/TimelessMoments/PhotoBoothHomePage/Untitled_design_6_yibsqh"
);

const bannerMobile = cld.video(
  "v1666810770/TimelessMoments/PhotoBoothHomePage/Untitled_design_7_igsivq"
);

const Intro = () => {
  const isMobile = useMediaMax(theme.breakpoints.md);
  const className = isMobile ? "g-0" : "go mb-3";

  const intro = (
    <Container
      className={className}
      // style={{
      //   background:
      //     "linear-gradient(-60deg, #F4EAEA 15%, #F5EBEB 15%, #C28CC2 41%, #43438C 67%, #215369 85%)",
      //   backgroundSize: "200% 200%",
      //   animation: "ani_gradient 5s ease infinite",
      // }}
    >
      {isMobile ? (
        <div>
          <AdvancedVideo cldVid={bannerMobile} autoPlay muted loop />
        </div>
      ) : (
        <div>
          <AdvancedVideo cldVid={bannerDesktop} autoPlay muted loop />
        </div>
      )}
    </Container>
  );

  return intro;
};

const Options = () => {
  const isMobile = useMediaMax(theme.breakpoints.md);
  const flexDirection = isMobile ? "flex-col mt-3" : "flex-col";
  const imageWidth = isMobile ? "100%" : "100%";
  const card1 = (
    <NavLink
      className={flexDirection}
      to={solicitareOfertaURL}
      title="Vezi detalii"
      aria-label="vezi-detalii"
      style={{
        color: theme.colors.customBlack,
        fontFamily: "Montserrat",
        fontSize: "16px",
        textDecoration: "none",
        width: "80%",
      }}
    >
      <Card className={flexDirection}>
        <Card.Img
          variant="top"
          src="https://res.cloudinary.com/dd6vph7bm/image/upload/v1666803752/TimelessMoments/PhotoBoothHomePage/photos/Say_Cheese_kuuth2.png"
          alt="Image cap"
          style={{ width: imageWidth }}
        />
        <Card.Body>
          <Card.Title>Cabina Foto Oglinda Vintage</Card.Title>

          <button
            type="button"
            className="btn"
            style={{ backgroundColor: "#438A7E", color: theme.colors.white }}
          >
            Vezi detalii
          </button>
        </Card.Body>
      </Card>
    </NavLink>
  );

  const card2 = (
    <NavLink
      className={flexDirection}
      to={solicitareOfertaURL}
      title="Vezi detalii"
      aria-label="vezi-detalii"
      style={{
        color: theme.colors.customBlack,
        fontFamily: "Montserrat",
        fontSize: "16px",
        textDecoration: "none",
        width: "80%",
      }}
    >
      <Card className={flexDirection}>
        <Card.Img
          className="fluid"
          variant="top"
          src="https://res.cloudinary.com/dd6vph7bm/image/upload/v1666804682/TimelessMoments/PhotoBoothHomePage/photos/Say_Cheese_1_qhpnbs.png"
          alt="Cabina Foto"
          style={{ width: imageWidth }}
        />
        <Card.Body>
          <Card.Title>360 x Video Booth</Card.Title>

          <button
            type="button"
            className="btn"
            style={{ backgroundColor: "#438A7E", color: theme.colors.white }}
          >
            Vezi detalii
          </button>
        </Card.Body>

        {/* <ListGroup
        className="list-group-flush"
        style={{
          color: theme.colors.customBlack,
          fontFamily: "Montserrat",
          fontSize: "14px",
        }}
      >
        <ListGroup.Item>
          Închiriaza Video Booth 360 X pentru un party memorabil fie el nunta,
          aniversare sau petrecerea de craciun a companie
        </ListGroup.Item>
        <ListGroup.Item>
          Foloseste 360 Slow Motion Booth pentru o campanie de activare
        </ListGroup.Item>
        <ListGroup.Item>
          Ajută-te de 360x Video Booth pentru promovarea brandului tău
        </ListGroup.Item>
        <ListGroup.Item>
          Alege ”Cabina Video 360” atunci cand vrei ceva diferit la evenimentul
          tau
        </ListGroup.Item>
        <ListGroup.Item>
          Adu 360 x Video Booth la evenimentul tau pentru vizibilitate online
          360
        </ListGroup.Item>
        <ListGroup.Item>
          Slow Motion Booth pentru o lansare de produs originala
        </ListGroup.Item>
      </ListGroup> */}
        {/* <Card.Body>
        <NavLink
          to={solicitareOfertaURL}
          title="Solicita oferta personalizata"
          aria-label="solicita-oferta-personalizata"
          style={{
            color: theme.colors.customBlack,
            fontFamily: "Montserrat",
            fontSize: "16px",
          }}
        >
          <p>Solicită oferta</p>
        </NavLink>
      </Card.Body> */}
      </Card>
    </NavLink>
  );

  const intro = isMobile ? (
    <Row className="d-flex w-100 align-items-center justify-content-center ">
      {card2}
      {card1}
    </Row>
  ) : (
    <Row className="d-flex justify-content-center">
      <Col className="d-flex flex-column align-items-center justify-content-start">
        {card2}
      </Col>
      <Col className="d-flex flex-column align-items-center justify-content-start">
        {card1}
      </Col>
    </Row>
  );

  return intro;
};

const CabinaFotoHome = () => {
  window.document.title = `${appTitle}: Cabina Foto Home`;

  return (
    <div className="d-flex flex-col w-full">
      <Intro />

      <div className="container fluid g-0">
        <Options />
      </div>
    </div>
  );
};

export default CabinaFotoHome;
