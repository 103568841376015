import React from "react";
import theme from "../../styles";

const PoliticaConfidentialitate = () => {
  return (
    <div
      className="container w-75 justify-content-center"
      style={{
        color: theme.colors.customBlack,
        fontFamily: "Cormorant Garamond",
        fontSize: "18px",
      }}
    >
      <div className="row align-self-center">
        <p className="text-center fs-4">
          Politică privind prelucrarea datelor cu caracter personal și
          confidențialitatea acestora
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Cine prelucrează datele cu caracter personal?
        </p>
        <p>
          SC TIMELESS MOMENTS SRL în calitate de autor, proprietar și
          administrator al site-ului web www.timelessmoments.ro, respectă
          caracterul privat și securitatea prelucrării datelor cu caracter
          personal al fiecărei persoane care accesează site-ul web în vederea
          efectuării de comenzi online. Datele dumneavoastră cu caracter
          personal vor fi prelucrate de Societatea TIMELESS MOMENTS SRL cu
          sediul social în Loc. Pleșoiu, Județul Olt, Str. Mihai Eminescu, Nr.
          157, înmatriculată la Oficiul Registrului Comerțului de pe lângă
          Tribunalul OLT sub nr. J28/586/08.06.2022, Cod Unic de Înregistrare:
          RO46267392, telefon: +4 0754.577.706, e-mail:
          contact@timelessmoments.ro.
        </p>
        <p>
          SC TIMLESS MOMENTS SRL priveste confidentialitatea datelor ca o
          componenta fundamentala a activitatii online. Politica de
          confidentialitate se concentreaza pe prelucrarea corecta si legala a
          datelor cu caracter personal, asigurand in acelasi timp
          confidentialitatea, integritatea si disponibilitatea.
        </p>
        <p>
          Societatea TIMELESS MOMENTS SRL își ia angajamentul de a lua măsurile
          necesare pentru protejarea datelor cu caracter personal, cu
          respectarea Regulamentului (UE) 2016/679 si reglementărilor în vigoare
          la nivel național.
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Datele care vor fi prelucrate{" "}
        </p>
        <p>Vom prelucra următoarele categorii de date personale: </p>
        <ul>
          <li>- date de identificare precum nume, prenume, adresă;</li>
          <li>- date de contact, precum număr de telefon, adresă de email;</li>
          <li>- date biometrice, precum imagini foto/video.</li>
        </ul>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Scopurile si temeiurile pentru prelucrarea datelor cu caracter
          personal
        </p>
        <p>
          Datele personale ale clienților care au nevoie de servicii furnizate
          de SC TIMELESS MOMENTS SRL, cum ar fi numele și adresa, vor fi
          colectate și prelucrate pentru a face demersurile în vederea
          încheierii unui contract sau executării unui contract, precum și
          pentru livrarea produselor.{" "}
        </p>
        <p>Analiza noastră arată că temeiurile legale corecte sunt: </p>
        <ul>
          <li>
            - obligația legală (Art. 6 (1) c) din GDPR) pentru datele personale
            colectate pentru facturare;
          </li>
          <li>
            - executarea unui contract și/sau obligație legala – în funcție de
            datele colectate (Art. 6. (1) b) si/sau c) din GDPR)
          </li>
        </ul>
        <p>
          Consimțământul dumneavoastră privitor la imagini foto-video este
          necesar pentru a presta serviciul de închiriere cabine foto care
          constă în executarea de poze unor terți persoane și imprimare, pe loc,
          a fotografiilor.
        </p>
        <p>
          Consimțământul dumneavoastră privitor la imagini foto-video este
          necesar pentru a presta serviciul de închiriere platforme video 360
          care constă în executarea de scurte filmari unor terți persoane și
          distribuirea, pe loc, a filmarilor.
        </p>
        <p>
          Consimțământul dumneavoastră privitor la imagini foto-video este
          necesar pentru a presta serviciul de fotografie/videografie de
          eveniment, care constă în înregistrarea pe bandă a evenimentului si în
          executarea unor fotografii ce vor fi distribuite ulterior.
        </p>
        <p>
          Consimțământul pentru folosirea imaginilor poate fi retras oricând.
        </p>
        <p>
          Datele dumneavoastră de contact, cum ar fi, număr de telefon, adresă
          de email, vor fi prelucrate în vederea prestării serviciului
          contractat precum și pentru livrarea pozelor / filmărilor în format
          digital și/sau a documentelor necesare prestării serviciului. Datele
          dumneavoastră personale cuprinse în documente justificative vor fi
          păstrate de noi timp de 5 ani, începând cu data încheierii
          exercițiului financiar (anului) în care au fost colectate. Prin
          documente justificative se înțelege: facturi fiscale, deconturi,
          contracte de prestări servicii și altele asemenea.
        </p>
        <p>
          Imaginile foto/video obținute prin prestarea serviciilor de închiriere
          cabina foto și închiriere platforma 360 vor fi șterse din evidențele
          SC TIMELESS MOMENTS SRL în termen de cel mult 30 de zile de la data
          încheierii relațiilor contractuale, sau de la data retragerii
          consimțământului, după caz.
        </p>
        <p>
          Imaginile foto/video obținute prin prestarea serviciilor de fotografie
          si videografie vor fi păstrate în evidențele SC TIMELESS MOMENTS SRL
          atât timp cât este necesar pentru realizarea scopului pentru care au
          fost colectate și vor fi arhivate în maximum 90 de zile de la
          îndeplinirea acestui scop. Aceste imagini foto/video vor fi păstrate
          în evidențele SC TIMELESS MOMENTS SRL timp de 4 ani, începând cu data
          încheierii exercițiului financiar (anului) în care au fost colectate
          sau până retragerea consimțământului, dacă este cazul.
        </p>
        <p>
          Datele de contact (adresă de email, număr de telefon) vor fi șterse în
          termen de maximum 90 de zile de la data la care relațiile contractuale
          s-au încheiat sau de la data retragerii consimțământului. Orice alte
          date personale pe care le colectăm de la dumneavoastră vor fi păstrate
          atât timp cât este necesar realizarea scopului pentru care au fost
          colectate și vor fi șterse din evidențele noastre în maximum 90 de
          zile de la îndeplinirea acestui scop sau de la retragerea
          consimțământului, dacă este cazul.
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Transferul datelor cu caracter personal către terțe părți{" "}
        </p>
        <p>
          Datele cu caracter personal colectate și prelucrate de SC TIMELESS
          MOMENTS SRL pot fi transmise către terțe părți dacă aceasta este o
          obligație legală a societății, ca urmare a solicitării persoanei
          vizate sau la solicitarea unui entități publice, în vederea
          îndeplinirii atribuțiilor specifice care îi revin prin lege.
        </p>
        <p>
          Datele dumneavoastră cu caracter personal pot fi solicitate și
          transmise de societate către: inspectori ai Agenției Naționale de
          Administrare Fiscală, organe ale poliției judiciare, instanțe
          judecătorești, executori judecătorești, precum și orice altă
          instituție sau autoritate publică ale cărei atribuții includ
          solicitarea și obținerea de documente ce conțin date cu caracter
          personal de la persoane juridice de drept privat.
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Drepturile clienților si colaboratorilor SC TIMELESS MOMENTS SRL
        </p>

        <p>
          În vederea respectării drepturilor dumneavoastră, vă informăm care
          sunt acestea și cum pot fi ele exercitate, în conformitate cu
          dispozițiile Legii nr. 677/2001, persoanele înregistrate, in calitate
          de persoane vizate, au următoarele drepturi:
        </p>

        <ul>
          <li>- Dreptul la informare (art.12);</li>
          <li>- Dreptul de acces la date cu caracter personal (art.13);</li>
          <li>
            - Dreptul de intervenție asupra datelor cu caracter personal
            (art.14);
          </li>
          <li>- Dreptul de opoziție (art.15); </li>
          <li>- Dreptul de a nu fi supus unei decizii individuale (art.17);</li>
          <li>- Dreptul de a se adresa justiției (art.18).</li>
        </ul>
        <p>
          Orice informație furnizată de dumneavoastră va fi considerată și va
          reprezenta consimțământul dumneavoastră expres ca datele dumneavoastră
          personale să fie folosite de SC TIMELESS MOMENTS SRL in conformitate
          cu scopurile menționate mai jos. În vederea respectării acestor
          drepturi, precum și pentru orice solicitare legate de informații, de
          accesul la date, de rectificare sau ștergere a datelor vor putea fi
          exercitate prin una din următoarele variante: o adresă scrisă,
          înaintată către SC TIMELESS MOMENTS SRL, trimisă la adresa sediului
          social sau o cerere trimisă prin email către Responsabilul cu
          prelucrarea datelor. SC TIMELESS MOMENTS SRL va răspunde în termenul
          cel mai scurt, dar nu mai mult de 30 de zile, oricărei solicitări
          venite din partea unei persoane vizate. Pentru orice nelămurire,
          solicitare sau reclamație referitoare la colectarea și folosirea
          datelor cu caracter personal, orice beneficiar, reprezentant legal al
          unui beneficiar ori orice altă persoană vizată se poate adresa
          Responsabilului cu protecția datelor, printr-un email, la adresa
          contact@timelessmoments.ro.
        </p>

        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Notificări de vulnerabilitate
        </p>
        <p>
          In cazul in care, din motive independente de noi, este detectata o
          vulnerabilitate sau un acces neautorizat la date, te vom anunta si vom
          face asta public in termen de maxim 72h din momentul identificarii
          bresei.
        </p>
        <p>
          Informații despre GDPR gasesti pe siteul Uniunii Europenedespre GDPR,
          aici:
          <a href="https://www.eugdpr.org">
            <p>www.eugdpr.org</p>
          </a>
        </p>

        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Reclamații
        </p>
        <p>
          In cazul in care considerati ca prelucrarea datelor dumneavoastra cu
          caracter personal incalca GDPR (Regulamentul general privind protectia
          datelor), puteti depune o plangere la o autoritatea de supraveghere.
        </p>

        <p
          style={{
            fontWeight: "bold",
          }}
        >
          Modificări la această Declarație de confidențialitate
        </p>
        <p>
          Această Politică de Confidențialitate poate fi actualizată din când în
          când ca urmare a modificărilor legislației relevante în domeniul
          protecției datelor personale. În cazul în care se fac modificări
          semnificative, veți afla prin intermediul unei notificari pe site
          inainte de intrarea in vigoare a modificării. Sunteți încurajati să
          verificați periodic această pagină pentru cele mai recente informații
          despre practicile de confidențialitate.
        </p>
      </div>
    </div>
  );
};

export default PoliticaConfidentialitate;
