import React, { Suspense, lazy, useLayoutEffect } from "react";
import styled from "styled-components/macro";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Home } from "./home";
import { Contact } from "./contact";
import {
  homeURL,
  contactURL,
  photoboothURL,
  politicaConfidentialitateURL,
  politicaCookiesURL,
  solicitareOfertaURL,
} from "./config";
import CabinaFotoHome from "./cabina-foto/CabinaFotoHome";

import "bootstrap/dist/css/bootstrap.min.css";
import PoliticaConfidentialitate from "./components/UtilLinks/PoliticaConfidentialitate";
import PoliticaCookies from "./components/UtilLinks/PoliticaCookies";
import CookieConsentComponent from "./components/CookieConsent";
import ContactForm from "./contact/ContactForm";

const NavigationBar = lazy(
  () => import("./components/NavigationBar/NavigationBar")
);

const Main = styled.section<any>`
  height: 100%;
  min-height: 100vh;
  display: block;
  color: #white;
`;

const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export const App = () => {
  return (
    <Suspense>
      <Main role="main">
        <NavigationBar />

        <Wrapper />
        <Routes>
          <Route path={homeURL} element={<Home />} />
          <Route path={photoboothURL} element={<CabinaFotoHome />} />
          {/* Maybe get creative, and remove contact, have one pager, but with mobile navigation that only show contacts/social media links? */}
          <Route path={contactURL} element={<Contact />} />
          <Route
            path={politicaConfidentialitateURL}
            element={<PoliticaConfidentialitate />}
          />
          <Route path={politicaCookiesURL} element={<PoliticaCookies />} />
          <Route path={solicitareOfertaURL} element={<ContactForm />} />
          <Route path="/*" element={<Navigate to={photoboothURL} replace />} />
        </Routes>
        <CookieConsentComponent></CookieConsentComponent>

        <Footer />
      </Main>
    </Suspense>
  );
};

export default App;
