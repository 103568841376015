import React from "react";
import {
  instagramURL,
  personalLocation,
  email1,
  personalPhoneNumber,
  appTitle,
} from "../config";
import ContactForm from "./ContactForm";
import { useMediaMax } from "../utils";
import theme from "../styles";

const Item = ({ title, value, href, iconName }: any) => (
  <div
    className="flex flex-col justify-center items-center "
    style={{
      fontFamily: "Montserrat",
      fontSize: 14,
    }}
  >
    <div className="text-4xl -mb-2 md:-mb-4">
      <ion-icon name={iconName}></ion-icon>
    </div>
    <div className="text-center">
      <h6 className="text-2xl hidden md:block">{title}</h6>
      <a
        href={href}
        title={value}
        className="mt-2 text-dark text-opacity-75 md-text-opacity-50 hover:text-opacity-100 focus:text-opacity-100"
      >
        {value}
      </a>
    </div>
  </div>
);

const Contact = () => {
  window.document.title = `${appTitle}: Contact`;
  const isMobile = useMediaMax(theme.breakpoints.md);
  const flexDirection = isMobile
    ? "d-flex flex-column w-100"
    : "d-flex flex-row w-100";

  return (
    <div className="container fluid g-5">
      <div className={flexDirection}>
        <div className="flex-grow-1">
          <ContactForm></ContactForm>
        </div>
        <div className="d-flex flex-column justify-center items-center">
          <h3 className={isMobile ? "mt-5" : ""}>Date de contact</h3>
          <Item
            title="E-mail"
            value={email1}
            href={`mailto:${email1}`}
            iconName="mail-outline"
          />
          <Item
            title="Phone"
            value={personalPhoneNumber}
            href={`tel:${personalPhoneNumber}`}
            iconName="call-outline"
          />
          <Item
            title="Location"
            value={personalLocation}
            iconName="location-outline"
          />
          <ul className="flex flex-row justify-center m-0 mt-2 md:mt-8 list-none w-full p-8">
            <li className="mr-2">
              <a
                className="p-4 text-2xl hover:opacity-75"
                href={instagramURL}
                title="Instagram – Timeless Moments"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram fa-lg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
